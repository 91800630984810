// src/components/Services.js
import React from 'react';
import './Services.css';

function Services() {
  return (
    <section id="services" className="services">
      <h2>Our Services</h2>
      <div className="service-cards">
        <div className="service-card">
          <h3>Web Development</h3>
          <p>Custom websites built with the latest technologies.</p>
        </div>
        <div className="service-card">
          <h3>Design</h3>
          <p>Beautiful and user-friendly designs to make your business stand out.</p>
        </div>
        <div className="service-card">
          <h3>SEO</h3>
          <p>Optimizing your website to rank higher on search engines.</p>
        </div>
      </div>
    </section>
  );
}

export default Services;
