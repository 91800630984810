// src/components/About.js
import React from 'react';
import './About.css';

function About() {
  return (
    <section id="about" className="about">
      <h2>About Us</h2>
      <p>Rose Web Designs is dedicated to crafting visually stunning and highly functional websites for businesses of all sizes. Our mission is to help you succeed online with innovative design and expert web development.</p>
    </section>
  );
}

export default About;
