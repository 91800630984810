// src/App.js
import React from 'react';
// eslint-disable-next-line
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import Home from './components/Home';
import Services from './components/Services';
import About from './components/About';
import Portfolio from './components/Portfolio';
import Contact from './components/Contact';
import Footer from './components/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';


function App() {
  return (
    <div className="App">
     
  <Router>
      <Header />
      <Routes>
         <Route path='/' exact element={< Home />} />
        <Route path='/Services' element={<Services />} />
        <Route path='/about' element={<About />} />
        <Route path='/portfolio' element={<Portfolio />} />
        <Route path='/contact' element= {<Contact />} />
      </Routes>
      <h1>This Site is under construction.</h1>
      <Footer />
  </Router>
      
    </div>
  );
}

export default App;
